<!-- Localized -->
<template>
    <label class="switch" :class="{'opacity-50': disabled}">
        <input @change="$emit('change', $event)" type="checkbox"  v-bind="$attrs" :disabled="disabled || loading" />
         <Loader v-if="loading" size="4" />
        <span v-else class="slider round"></span>
    </label>
</template>

<script>
import Loader from "@shared/loader"

export default {
    name: "toggle",
    components: {
        Loader
    },
    props: {
        disabled:{
            type:Boolean,
            default: false
        },
        loading:{
            type:Boolean,
            default:false
        },
    },
    data() {
        return {};
    },
    computed: {},
    async mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 18px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: none;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 5px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: none;
}

input:checked + .slider {
    background-color: var(--theme-color-main);
}

input:focus + .slider {
    box-shadow: none;
    border: none;
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
